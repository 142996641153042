<template>
    <v-container class="mt-3">
        <AdBTWR v-if="!user.isAdFree"/>

        <div v-if="user.authenticated && (user.isAdFree || user.isPremium)">
            <v-row justify="center">
                <v-col cols="12" xl="12" class="pt-0">
                    <h1 class="page-header font-weight-light">Generate Reports</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="font-weight-light">Repeaters by State</v-card-title>
                        <v-card-text>
                            <v-autocomplete
                                v-model="repeaters.state"
                                label="State"
                                :items="states"
                                outlined
                                hide-details
                            ></v-autocomplete>

                            <v-radio-group v-model="repeaterFormat" rows>
                                <template v-slot:label><span class="subtitle-1 font-weight-bold">Output Format:</span>
                                </template>
                                <v-radio label="PDF" value="pdf"></v-radio>
                                <v-radio label="CHIRP CSV" value="csv" v-if="user.isPremium"></v-radio>
                                <v-radio label="Google Earth KML" value="kml" v-if="user.isPremium"></v-radio>
                            </v-radio-group>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="rfaccent2"
                                :dark="!!repeaters.state"
                                @click="downloadRepeaterReport"
                                :disabled="!repeaters.state"
                                :loading="repeaters.loading"
                            >
                                <v-icon class="mr-1">mdi-download</v-icon>
                                Repeater Report
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title class="font-weight-light">Active Licenses by State</v-card-title>
                        <v-card-text>
                            <v-autocomplete
                                v-model="licenses.state"
                                label="State"
                                :items="states"
                                outlined
                            ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="rfaccent2"
                                :dark="!!licenses.state"
                                @click="downloadLicenseReport"
                                :disabled="!licenses.state"
                                :loading="licenses.loading"
                            >
                                <v-icon class="mr-1">mdi-download</v-icon>
                                License Report
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card v-if="user.isPremium">
                        <v-card-title class="font-weight-light">Repeater Access Requests by Status</v-card-title>
                        <v-card-text>
                            <v-autocomplete
                                v-model="requests.status"
                                label="Request Status"
                                :items="requestStatus"
                                outlined
                            ></v-autocomplete>

                            <v-autocomplete
                                v-model="requests.repeater"
                                label="Repeater (Optional)"
                                :items="myRepeaters"
                                outlined
                            ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="rfaccent2"
                                :dark="!!requests.state"
                                @click="downloadRequestReport"
                                :disabled="$_.isNull(requests.status) && $_.isNull(requests.repeater)"
                                :loading="requests.loading"
                            >
                                <v-icon class="mr-1">mdi-download</v-icon>
                                Access Request Report
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="user.authenticated">
            <PremiumMembershipRequired :user="user"/>
        </div>
        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to generate reports.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import statesList from '@/utils/states';
    import PremiumMembershipRequired from "@/components/PremiumMembershipRequired";
    import { useTitle } from '@vueuse/core';
    import axios from "axios";
    import config from "@/config";

    export default {
        name: 'Reports',

        components: {PremiumMembershipRequired, AdBTWR},

        props: ['user'],

        data: () => ({
            states: statesList,
            myRepeaters: [],
            requestStatus: [
                {text: 'Any Status', value: ''},
                {text: 'Approved', value: 'approved'},
                {text: 'Pending', value: 'pending'},
                {text: 'Denied', value: 'denied'}
            ],
            repeaterFormat: 'pdf',
            repeaters: {
                loading: false,
                state: null
            },
            requests: {
                loading: false,
                repeater: null,
                status: ''
            },
            licenses: {
                loading: false,
                state: null
            }
        }),

        methods: {
            downloadRepeaterReport() {
                this.repeaters.loading = true;
                setTimeout(() => {
                    this.repeaters.loading = false;
                }, 2000);
                window.location = `https://reports.mygmrs.com/repeaters/state/${this.repeaters.state}?format=${this.repeaterFormat}&token=${this.user.authToken}`;
            },

            downloadLicenseReport() {
                this.licenses.loading = true;
                setTimeout(() => {
                    this.licenses.loading = false;
                }, 2000);
                window.location = `https://reports.mygmrs.com/licenses/state/${this.licenses.state}?token=${this.user.authToken}`;
            },

            downloadRequestReport() {
                this.requests.loading = true;
                setTimeout(() => {
                    this.requests.loading = false;
                }, 2000);
                if (this.requests.repeater && this.requests.status) {
                    window.location = `https://reports.mygmrs.com/requests/repeater/${this.requests.repeater}/${this.requests.status}?token=${this.user.authToken}`;
                } else if (this.requests.repeater) {
                    window.location = `https://reports.mygmrs.com/requests/repeater/${this.requests.repeater}?token=${this.user.authToken}`;
                } else if (this.requests.status) {
                    window.location = `https://reports.mygmrs.com/requests/repeater/${this.requests.status}?token=${this.user.authToken}`;
                }
            },

            async fetchMyRepeaters() {
                try {
                    let axiosHeaders = {};
                    if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios
                        .get(config.API_LOCATION + '/repeaters?limit=9999&outdated=true&offline=true&owner=' + this.user.username, {
                            headers: axiosHeaders
                        });

                    this.myRepeaters = response.data.items.map(o => {
                        return {text: o.Name, value: o.ID}
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        },

        mounted() {
            useTitle('Generate Reports - myGMRS.com');

            this.fetchMyRepeaters();
        }
    }
</script>
